// functions/xp.js

// Function to get XP from local storage
export const getStoredXP = () => {
    const storedXP = localStorage.getItem('xp');
    return storedXP ? parseInt(storedXP) : 0; // Default XP if not set
};

// Function to save XP to local storage
export const saveXPToLocalStorage = (xp) => {
    localStorage.setItem('xp', xp);
};

// Function to update XP and handle level-up logic
export const updateXP = (currentXP, level) => {
    const maxXP = calculateMaxXP(level);
    let newXP = currentXP + 1;
    let newLevel = level;

    if (newXP >= maxXP) {
        newXP = 0;
        newLevel += 1;
    }

    // Save updated values
    saveXPToLocalStorage(newXP);
    return { newXP, newLevel };
};

// Function to calculate maximum XP based on level
export const calculateMaxXP = (level) => {
    return parseInt(100 * Math.pow(1.1, level));
};

// Function to get the XP from the database and update local storage
export const fetchAndSetXP = async () => {
    try {
    const { data } = await axios.get('/api/xp', { withCredentials: true });
    saveXPToLocalStorage(data.xp);
    return data.xp;
    } catch (error) {
    console.error('Error fetching XP:', error);
    return getStoredXP(); // Return local XP as fallback
    }
};

// Function to increase the XP in local storage
export const increaseXP = (amount) => {
    const currentXP = getStoredXP();
    const newXP = currentXP + parseFloat(amount);
    saveXPToLocalStorage(newXP);
    return newXP; // Return the updated XP
};