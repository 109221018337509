import React from 'react';
import Navigation from './Navigation';
import Modal from './Modal';
import Casino from './Casino';
import ErrorField from './ErrorField';


import { LanguageProvider } from './language'; // Import LanguageProvider
import './index.css'; // Import global CSS styles (if you have any)

function CasinoPage() {
  return (
    <LanguageProvider>
      <>
      <Navigation />
      <Casino />
      <Modal />
      <ErrorField />
      </>
    </LanguageProvider>
  );
}

export default CasinoPage;
