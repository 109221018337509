import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import { useLanguage } from './language'; // Import the context

function Footer() {
  const { language, setLanguage, translations } = useLanguage(); // Get language, setter, and translations

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value); // Update language based on user selection
  };

  return (
    <footer>
      <div className="footer-content">
        <div className="footer-links">
          {/* Use translations for link text */}
          <Link to="/contact">{translations.contact}</Link>
          <Link to="/about">{translations.about}</Link>
          <Link to="/privacy">{translations.privacyPolicy}</Link>
          <Link to="/terms">{translations.termsOfService}</Link>
        </div>
        <div className="footer-language">
          <label htmlFor="language">{translations.language}</label>
          <select
            id="language"
            name="language"
            value={language}
            onChange={handleLanguageChange}
          >
            <option value="en">English</option>
            <option value="ru">Русский</option>
          </select>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Case Clicker. All rights reserved.</p>
        <a href="https://beta.publishers.adsterra.com/referral/JBBEbijBkF"><img alt="banner" src="https://landings-cdn.adsterratech.com/referralBanners/gif/120x60_adsterra_reff.gif" /></a>
      </div>
    </footer>
  );
}

export default Footer;
