import React from 'react';
import './Casino.css';

const Casino = () => {
  return (
    <div 
      className="casino-section"
      style={{ backgroundImage: 'url(/CasinoBack.webp)' }} // Directly access from public folder
    >
      {/* Content will be added later */}
    </div>
  );
};

export default Casino;
