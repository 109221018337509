import React from 'react';

function Shop({ setBalance, setXp, addTickets, upgradeToMaxLevel }) {
  const handlePackPurchase = (pack) => {
    const { amount, xp, gold, silver, bronze, contraband, clickMultiplier, forever } = pack;

    if (amount) setBalance((prev) => (parseFloat(prev) + amount).toFixed(2));
    if (xp) setXp((prev) => prev + xp);
    if (gold || silver || bronze || contraband) {
      addTickets({ gold, silver, bronze, contraband });
    }
    if (clickMultiplier) alert(`Clicker booster activated: x${clickMultiplier} money & XP per click!`);
    if (forever) alert(`Permanent click multiplier & XP applied!`);
    if (upgradeToMaxLevel) upgradeToMaxLevel();
  };

  const formatPackDetails = (pack) => {
    const details = [];
    if (pack.amount) details.push(<span className="green-text">{`+$${pack.amount}`}</span>);
    if (pack.xp) details.push(<span className="blue-text">{`+${pack.xp} XP`}</span>);
    if (pack.gold) details.push(<span className="gold-text">{`+${pack.gold} Gold Ticket(s)`}</span>);
    if (pack.silver) details.push(<span className="silver-text">{`+${pack.silver} Silver Ticket(s)`}</span>);
    if (pack.bronze) details.push(<span className="bronze-text">{`+${pack.bronze} Bronze Ticket(s)`}</span>);
    if (pack.contraband) details.push(<span className="purple-text">{`+${pack.contraband} Contraband Ticket(s)`}</span>);
    if (pack.clickMultiplier) details.push(<span className="orange-text">{`x${pack.clickMultiplier} money & XP per click!`}</span>);
    if (pack.forever) details.push(<span className="yellow-text">{`Forever effect active!`}</span>);
    if (pack.day) details.push(<span className="yellow-text">{`Effect active for 24 hours!`}</span>);
    if (pack.upgradeToMaxLevel) details.push(<span className="red-text">Max level upgrade!</span>);
    return details;
  };
  
  // Pack categories
  const boosters = [
    {
      label: 'Daily bonus',
      description: 'Enjoy a daily boost of $100, 250 XP, and 1 Gold Ticket, forever!',
      price: '$0.29',
      image: '/daily_reward.png',
      amount: 100,
      xp: 250,
      gold: 1,
      forever: true,
    },
    {
      label: 'Clicker Booster',
      description: 'Amplify your earnings with 5x money and XP per click for 1 day!',
      price: '$0.99',
      image: '/clicker_booster.png',
      clickMultiplier: 5,
      day: true
    },
  ];
  
  const regularPacks = [
    {
      label: 'Starter Pack',
      description: 'Kickstart your journey with $1000, 2000 XP, and 10 Gold Tickets!',
      price: '$0.49',
      image: '/starter_pack.png',
      amount: 1000,
      xp: 2000,
      gold: 10,
    },
    {
      label: 'Champion Pack',
      description: 'Level up your game with $4800, 8000 XP, and 30 Gold Tickets!',
      price: '$1.99',
      image: '/champion_pack.png',
      amount: 4800,
      xp: 8000,
      gold: 30,
    },
    {
      label: 'Sigma Pack',
      description: 'Achieve new heights with $46000, 1,000,000 XP, 50 Gold, Silver, and Bronze Tickets each!',
      price: '$9.99',
      image: '/sigma_pack.png',
      amount: 46000,
      xp: 1000000,
      gold: 50,
      silver: 50,
      bronze: 50,
    },
    {
      label: 'Ultimate Pack',
      description: 'Unlock your full potential with $250,000, 10,000,000 XP, x20 money & XP per click forever, and Max Level Upgrade!',
      price: '$24.99',
      image: '/ultimate_pack.png',
      amount: 250000,
      xp: 10000000,
      contraband: 50,
      clickMultiplier: 20,
      forever: true,
      upgradeToMaxLevel: true,
    },
  ];  

  return (
    <section className="shop-section">
      <h1 className="shop-title">Exclusive Packs & Boosts</h1>

      <div className="category">
        <h2 className="category-title">Boosters & Permanent Bonuses</h2>
        <div className="currency-grid">
          {boosters.map((pack) => (
            <div key={pack.label} className="currency-tile" onClick={() => handlePackPurchase(pack)}>
              <img src={pack.image} alt={pack.label} className="currency-image" />
              <div className="tile-content">
                <h2 className="pack-title">{pack.label}</h2>
                <div className="pack-details">{formatPackDetails(pack)}</div>
              </div>
              <div className="tile-price">{pack.price}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="category">
        <h2 className="category-title">Packs for Instant Rewards</h2>
        <div className="currency-grid">
          {regularPacks.map((pack) => (
            <div key={pack.label} className="currency-tile" onClick={() => handlePackPurchase(pack)}>
              <img src={pack.image} alt={pack.label} className="currency-image" />
              <div className="tile-content">
                <h2 className="pack-title">{pack.label}</h2>
                <div className="pack-details">{formatPackDetails(pack)}</div>
              </div>
              <div className="tile-price">{pack.price}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Shop;
