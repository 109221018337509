// functions/balance.js
import axios from 'axios';

// Function to get the balance from local storage
export const getStoredBalance = () => {
  const storedBalance = localStorage.getItem('balance');
  return storedBalance ? parseFloat(storedBalance) : 500; // Default balance if not set
};

// Function to save the balance to local storage
export const saveBalanceToLocalStorage = (balance) => {
  localStorage.setItem('balance', balance.toFixed(2));
};

// Function to get the balance from the database and update local storage
export const fetchAndSetBalance = async () => {
  try {
    const { data } = await axios.get('/api/balance', { withCredentials: true });
    saveBalanceToLocalStorage(data.balance);
    return data.balance;
  } catch (error) {
    console.error('Error fetching balance:', error);
    return getStoredBalance(); // Return local balance as fallback
  }
};

// Function to update the balance in local storage
export const updateBalance = (newBalance) => {
  if (newBalance >= 0) { // Ensure the balance is not negative
    saveBalanceToLocalStorage(newBalance);
  } else {
    console.error('Balance cannot be negative.');
  }
};

// Function to increase the balance in local storage
export const increaseBalance = (amount) => {
  const currentBalance = getStoredBalance();
  const newBalance = currentBalance + parseFloat(amount);
  updateBalance(newBalance);
  return newBalance; // Return the updated balance
};

// Function to decrease the balance in local storage
export const decreaseBalance = (amount) => {
  const currentBalance = getStoredBalance();
  const newBalance = currentBalance - parseFloat(amount);
  updateBalance(newBalance);
  return newBalance; // Return the updated balance
};