import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App'; // Import the main App component
import ClickerPage from './ClickerPage'; // Import the ClickerPage component
import CaseOpenerPage from './CaseOpenerPage'; // Import the ClickerPage component
import CaseChoicePage from './CaseChoicePage'; // Import the ClickerPage component
import InventoryPage from './InventoryPage'; // Import the InventoryPage component
import GamesPage from './GamesPage'; // Import the InventoryPage component
import AboutPage from './AboutPage'
import ContactPage from './ContactPage'
import PrivacyPolicyPage from './PrivacyPolicyPage'
import TermsOfServicePage from './ToSPage'
import ShopPage from './ShopPage'
import CasinoPage from './CasinoPage'

function AppRoutes() {
  return (
    <Router>
      <Routes>
        {/* Route for the main page */}
        <Route path="/" element={<App />} />

        {/* Route for the clicker page */}
        <Route path="/clicker" element={<ClickerPage />} />

        {/* Route for the clicker page */}
        <Route path="/caseopener" element={<CaseOpenerPage />} />

        {/* Route for the clicker page */}
        <Route path="/inventory" element={<InventoryPage />} />

        {/* Route for the clicker page */}
        <Route path="/cases" element={<CaseChoicePage />} />

        {/* Route for the clicker page */}
        <Route path="/games" element={<GamesPage />} />

        {/* Route for the clicker page */}
        <Route path="/about" element={<AboutPage />} />

        {/* Route for the clicker page */}
        <Route path="/contact" element={<ContactPage />} />

        {/* Route for the clicker page */}
        <Route path="/privacy" element={<PrivacyPolicyPage />} />

        {/* Route for the clicker page */}
        <Route path="/terms" element={<TermsOfServicePage />} />

        {/* Route for the clicker page */}
        <Route path="/Shop" element={<ShopPage />} />

        {/* Route for the clicker page */}
        <Route path="/Casino" element={<CasinoPage />} />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
