import React, { useState } from 'react';
import { useSpring, animated, config } from 'react-spring'; // Animation library

const Contact = () => {
  // Animations
  const slideIn = useSpring({
    transform: 'translateY(0)',
    from: { transform: 'translateY(50px)' },
    delay: 200,
    config: { tension: 200, friction: 15 },
  });

  const fadeIn = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 200,
    config: config.gentle,
  });

  const [formData, setFormData] = useState({ name: '', email: '', message: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Thank you for reaching out! We will get back to you soon.');
    setFormData({ name: '', email: '', message: '' });
  };

  return (
    <div className="contact-page">
      <animated.div style={fadeIn} className="contact-header">
        <h1>Contact Us</h1>
        <p>We're here to help! Reach out to us for support, feedback, or business inquiries.</p>
      </animated.div>

      <div className="contact-section">
        <animated.div style={fadeIn} className="email-contact">
          <h2>Email Us</h2>
          <div className="email-list">
            <p>
              <strong>Support, Feedback, Suggestions:</strong>{' '}
              <a href="mailto:caseclickerhelp@yahoo.com">caseclickerhelp@yahoo.com</a>
            </p>
            <p>
              <strong>Business Offers:</strong>{' '}
              <a href="mailto:artemiibakharev@gmail.com">artemiibakharev@gmail.com</a>
            </p>
          </div>
        </animated.div>
      </div>
    </div>
  );
};

export default Contact;
