import React from 'react';
import Navigation from './Navigation';
import Modal from './Modal';
import Container from './Container';
import CasesSection from './CasesSection';
import Footer from './Footer';
import ErrorField from './ErrorField';
import LuckyWheel from './LuckyWheel'
import PopupList from './Popups'

import { LanguageProvider } from './language'; // Import LanguageProvider
import './index.css'; // Import global CSS styles (if you have any)

function App() {
  return (
    <LanguageProvider>
      <>
      <Navigation />
      <Container />
      <Modal />
      <CasesSection />
      <Footer />
      <ErrorField />
      <LuckyWheel />
      <PopupList />
      </>
    </LanguageProvider>
  );
}

export default App;
