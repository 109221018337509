import React from 'react';
import Navigation from './Navigation';
import Footer from './Footer';
import Modal from './Modal';
import CaseOpener from './CaseOpener';
import { LanguageProvider } from './language'; // Import LanguageProvider
import './index.css'; // Import global CSS styles (if you have any)
import ErrorField from './ErrorField';

function CaseOpenerPage() {
  return (
    <LanguageProvider>
      <>
      <Navigation />
      <Modal />
      <CaseOpener />
      <Footer />
      <ErrorField />
      </>
    </LanguageProvider>
  );
}

export default CaseOpenerPage;
