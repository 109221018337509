import React, { useState, useRef } from 'react';
import gsap from 'gsap';
import './index.css';

const LuckeWheel = () => {
  const [isSpinning, setIsSpinning] = useState(false);
  const wheelRef = useRef(null);

  // Function to handle closing the modal
  const closeModal = () => {
    document.getElementById('lucky-wheel-cont').style.display = 'none';
  };

  const spinWheel = () => {
    if (isSpinning) return;
    setIsSpinning(true);

    const randomDegree = Math.floor(Math.random() * 360) + 1440; // Rotate multiple times
    gsap.to(wheelRef.current, {
      rotation: randomDegree,
      duration: 5,
      ease: 'power4.out',
      onComplete: () => setIsSpinning(false),
    });
  };

  return (
    <div id="lucky-wheel-cont" className="lucky-wheel-cont">
      <div className="modal-content-wheel">
        <h2>Spin the Lucky Wheel!</h2>
        {/* Close button now triggers the closeModal function */}
        <button className="close-btn" onClick={closeModal}>Close</button>
        <div className="wheel-container">
          <div ref={wheelRef} className="wheel">
            <div className="segment segment-1">
              <img src="case1.png" alt="Case 1" />
            </div>
            <div className="segment segment-2">
              <img src="case2.png" alt="Case 2" />
            </div>
            <div className="segment segment-3">
              <img src="case3.png" alt="Case 3" />
            </div>
            <div className="segment segment-4">
              <img src="case4.png" alt="Case 4" />
            </div>
          </div>
        </div>
        <button onClick={spinWheel} disabled={isSpinning} className="spin-button">
          {isSpinning ? 'Spinning...' : 'Spin'}
        </button>
      </div>
    </div>
  );
};

export default LuckeWheel;
