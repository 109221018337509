// functions/countCases.js


/**
 * Fetches and decodes the inventory tokens from localStorage.
 * @returns {Array} An array of decoded cases from the inventory.
 */
const fetchAndDecodeInventory = async () => {
  const localSkins = JSON.parse(localStorage.getItem('skins')) || [];

  const inventoryArray = [...localSkins]; // Array of tokenized items

  if (inventoryArray.length === 0) return [];

  try {
    const response = await fetch(`/api/decode-inventory`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ inventoryArray }),
    });

    const data = await response.json();
    console.log('Decoded Inventory:', data);

    if (data.items) {
      // Store decoded cases into localStorage for further use
      localStorage.setItem('decodedCases', JSON.stringify(data.items));
      return data.items;
    } else {
      console.warn('No items found in the decoded inventory.');
      return [];
    }
  } catch (error) {
    console.error('Error decoding inventory:', error);
    return [];
  }
};

/**
 * Counts the number of owned cases for each case name.
 * @param {Array} storedCases - The list of decoded cases stored locally.
 * @param {Array} allCases - The list of all cases fetched from the backend.
 * @returns {Object} An object where keys are case names and values are owned counts.
 */
const countCases = (storedCases, allCases) => {
  return allCases.reduce((acc, caseItem) => {
    const caseName = caseItem.name;

    // Find all instances of this case in stored cases
    const matchingCases = storedCases.filter((storedCase) => storedCase.name === caseName);

    // Count how many of this case are owned
    acc[caseName] = matchingCases.length;

    return acc;
  }, {});
};

/**
 * Fetches, decodes, and counts cases, updating the local state accordingly.
 * @param {Array} allCases - The list of all available cases.
 * @returns {Object} The counts of owned cases by case name.
 */
export const updateCaseCounts = async (allCases) => {
  const decodedCases = await fetchAndDecodeInventory();
  const counts = countCases(decodedCases, allCases);
  console.log('Case Counts:', counts);
  return counts;
};

export default updateCaseCounts;
