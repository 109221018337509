import React from 'react';
import Navigation from './Navigation';
import Footer from './Footer';
import Modal from './Modal';
import CaseChoice from './CaseChoice';
import { LanguageProvider } from './language'; // Import LanguageProvider
import './index.css'; // Import global CSS styles (if you have any)

function CaseChoicePage() {
  return (
    <LanguageProvider>
      <>
      <Navigation />
      <Modal />
      <CaseChoice />
      <Footer />
      </>
    </LanguageProvider>
  );
}

export default CaseChoicePage;
