import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios'; 
import { addSkinToLocalInventory, remCaseFromLocalInventory } from './functions/inv';
import updateCaseCounts from './functions/countCases';
import { showInsufficientBalanceError } from './functions/errors';

const CaseOpener = () => {
  const [balance, setBalance] = useState(0);
  const [cases, setCases] = useState([]);
  const [openedItem, setOpenedItem] = useState(null);
  const [decodedItem, setDecodedItem] = useState(null);
  const [owned, setOwned] = useState(0);
  const [storedCases, setStoredCases] = useState(0);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [isBuying, setIsBuying] = useState(false);
  const [isOpening, setIsOpening] = useState(false);
  const selectedCase = location.state?.selectedCase;

  const rarityColors = {
    Covert: '#DC4444',
    Classified: '#CD2ADB',
    Restricted: '#733CDB',
    'Mil-spec': '#4362E6',
    Industrial: '#5899D3',
    Consumer: '#BAC9DA',
    Special: '#E6A726',
  };

  useEffect(() => {
    const fetchCases = async () => {
      try {
        const response = await fetch(`/api/cases`);
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setCases(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching cases:', error);
      }
    };

    fetchCases();
  }, []);

  const updateOwnedState = async () => {
    if (cases.length > 0 && selectedCase) {
      const caseCounts = await updateCaseCounts(cases); // Fetch updated case counts
      setOwned(caseCounts[selectedCase.name] || 0); // Update the owned count
    }
  };

  useEffect(() => {
    updateOwnedState();
  }, [cases, selectedCase]);

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const response = await axios.get(`/api/balance`, { withCredentials: true });
        setBalance(response.data.balance);
      } catch (error) {
        console.error('Error fetching balance:', error);
      }
    };
    fetchBalance();
  }, []);

  const updateStoredCasesCount = () => {
    const storedItems = JSON.parse(localStorage.getItem('skins')) || [];
    setStoredCases(storedItems.length);
  };

  useEffect(() => {
    updateStoredCasesCount();
  }, []);

  const buyCase = async () => {
    if (!selectedCase) return;

    // Check if stored items exceed 200
    if (storedCases >= 200) {
        console.error('Cannot buy more cases, storage limit reached.');
        return;
    };

    if (balance < selectedCase.price) {
        console.error('Insufficient balance to open this case.');
        showInsufficientBalanceError();
        return;
    };

    setIsBuying(true);

    try {
        // Call addCase endpoint, which handles both balance reduction and adding the case
        const { data: tokenData } = await axios.post(
            '/api/add-case',
            { item: selectedCase },
            { withCredentials: true }
        );

        // Update balance and inventory
        setBalance(parseFloat(balance - selectedCase.price));
        addSkinToLocalInventory(tokenData);
        updateStoredCasesCount();
        updateOwnedState();
    } catch (error) {
        console.error('Error purchasing case:', error);
    } finally {
        setIsBuying(false);
    }
  };

  

  const openCase = async () => {
    if (!selectedCase) return;
    
    setIsOpening(true); // Disable the button at the start of the request

    try {
        // Fetch the inventory from local storage
        const localSkins = JSON.parse(localStorage.getItem('skins')) || [];

        // Check if the case exists in the inventory by name
        const existsResponse = await fetch(`/api/check-item-exists`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ inventoryArray: localSkins, name: selectedCase.name }),
        });

        const { exists } = await existsResponse.json();
        if (!exists) {
            console.error('Selected case does not exist in inventory.');
            return; // Exit the function if the item doesn't exist
        }

        remCaseFromLocalInventory(selectedCase.name);

        // If the case exists, proceed to open it
        const response = await fetch(`/api/open-case`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ caseId: selectedCase.id }),
        });

        if (!response.ok) {
            const errorResponse = await response.json();
            throw new Error(`Error opening case: ${errorResponse.error || 'Unknown error'}`);
        }

        const item = await response.json();
        setOpenedItem(item);
        addSkinToLocalInventory(item);
        decodeOpenedItem(item);
        updateStoredCasesCount();
        updateOwnedState();
    } catch (error) {
        console.error('Error opening case:', error);
    } finally {
      setIsOpening(false); // Re-enable the button after request completes
    }
};


  const decodeOpenedItem = async (item) => {
    try {
      const response = await axios.post(`/api/decode-inventory`, {
        inventoryArray: [{ token: item.token }],
      });

      if (response.status === 200) {
        setDecodedItem(response.data.items[0]);
      } else {
        console.error('Error decoding item:', response.data.error);
      }
    } catch (error) {
      console.error('Error calling decode API:', error);
    }
  };

  return (
    <div className="case-opener">
      <h2>Case Opener</h2>
      {loading ? (
        <p>Loading cases...</p>
      ) : (
        <>
          <h3>Selected Case: {selectedCase ? selectedCase.name : "None"}</h3>
          {selectedCase && (
            <img 
              src={selectedCase.image} 
              alt={selectedCase.name} 
              style={{ width: '150px', height: 'auto', marginBottom: '20px' }} 
            />
          )}
          <p className={`case-history-p ${ storedCases >= 200 ? 'red' : storedCases >= 150 ? 'orange' : 'white'}`}>
            Stored Items: {storedCases}
          </p>
          <button onClick={buyCase} disabled={!selectedCase || isBuying}>
            <p className="buy-case">Buy case - ${selectedCase.price}</p>
          </button>

          <button onClick={openCase} disabled={!selectedCase || isOpening}>
            <p className="open-case">Open Case ({owned})</p>
          </button>

          {decodedItem && (
            <div 
              className="result"
              style={{ 
                backgroundColor: `${rarityColors[decodedItem.rarity]}80`, 
                backdropFilter: 'blur(15px)', 
                padding: '20px',
                borderRadius: '10px',
                boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)' 
              }} 
            >
              <h3>You opened:</h3>
              <p>{decodedItem.name}</p>
              <img src={decodedItem.image} alt={decodedItem.name} />
              <p>Quality: {decodedItem.quality}</p> 
              <p>Price: ${decodedItem.price}</p> 
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CaseOpener;
