import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { calculateMaxXP } from './functions/xp';
import { useLanguage } from './language';
import axios from 'axios';

const getStoredValue = (key, defaultValue) => localStorage.getItem(key) || defaultValue;

function Navigation() {
  const [balance, setBalance] = useState(0);
  const [level, setLevel] = useState(parseInt(getStoredValue('level', '1')));
  const [xp, setXP] = useState(parseFloat(getStoredValue('xp', '0')));
  const [username, setUsername] = useState(getStoredValue('username', 'Guest'));
  const [avatar, setAvatar] = useState(getStoredValue('avatarImage', 'avatar_default.png'));
  const [showAdOpener, setShowAdOpener] = useState(false);
  const { translations } = useLanguage();

  const maxXP = calculateMaxXP(level);
  const xpPercent = Math.min(100, (xp / maxXP) * 100);

  useEffect(() => {
    setDocumentNavHeight();
    fetchData(`/api/xp`, ({ xp, level }) => {
      setXP(xp);
      setLevel(level);
      localStorage.setItem('xp', xp);
      localStorage.setItem('level', level);
    });
    fetchData(`/api/balance`, ({ balance }) => setBalance(balance));

    const updateStats = () => {
      setLevel(parseInt(getStoredValue('level', '1')));
      setXP(parseFloat(getStoredValue('xp', '0')));
      setUsername(getStoredValue('username', 'Guest'));
      setAvatar(getStoredValue('avatarImage', 'avatar_default.png'));
    };

    const handleXPChange = (event) => updateXP(parseFloat(event.detail));

    window.addEventListener('storage', updateStats);
    window.addEventListener('xpChange', handleXPChange);

    return () => {
      window.removeEventListener('storage', updateStats);
      window.removeEventListener('xpChange', handleXPChange);
    };
  }, [xp, level]);

  const fetchData = async (url, callback) => {
    try {
      const { data } = await axios.get(url, { withCredentials: true });
      callback(data);
    } catch (error) {
      console.error(`Error fetching from ${url}:`, error);
    }
  };

  const setDocumentNavHeight = () => {
    const updateNavHeight = () => {
      document.documentElement.style.setProperty(
        '--nav-height',
        `${document.querySelector('nav').offsetHeight - 0.01}px`
      );
    };
    updateNavHeight();
    window.addEventListener('resize', updateNavHeight);
    return () => window.removeEventListener('resize', updateNavHeight);
  };

  const updateXP = (newXP) => {
    let updatedXP = xp + newXP;
    let updatedLevel = level;
    if (updatedXP >= maxXP) {
      updatedLevel += 1;
      updatedXP -= maxXP;
      setLevel(updatedLevel);
      localStorage.setItem('level', updatedLevel);
    }
    setXP(updatedXP);
    localStorage.setItem('xp', updatedXP);
  };

  const handleProfileEdit = () => {
    document.getElementById('profile-modal').style.display = 'flex';
  };

  const handleWatchAd = () => {
    setShowAdOpener(true);
    const popunderScript = document.createElement('script');
    popunderScript.src = '//pl24820692.profitablecpmrate.com/65/89/2b/65892b68a305bbcdfc810309d3752588.js';
    document.body.appendChild(popunderScript);
    popunderScript.onload = () => document.body.removeChild(popunderScript);
  };

  const handleCloseAdOpener = () => {
    setShowAdOpener(false);
  };

  return (
    <>
      <nav>
        <Logo />
        <NavigationLinks translations={translations} />
        <UserStats 
          balance={balance} 
          level={level} 
          xp={xp} 
          maxXP={maxXP} 
          xpPercent={xpPercent} 
          translations={translations} 
          onWatchAd={handleWatchAd}
        />
        <UserProfile avatar={avatar} username={username} onProfileEdit={handleProfileEdit} />
      </nav>

      {showAdOpener && <AdOverlay onClose={handleCloseAdOpener} />}
    </>
  );
}

const Logo = () => (
  <Link to="/" className="logo">
    <img src="nav_logo.png" alt="Nav Logo" />
  </Link>
);

const NavigationLinks = ({ translations }) => (
  <ul className="nav-links">
    {['clicker', 'cases', 'games', 'inventory'].map((item) => (
      <li key={item}>
        <Link to={`/${item}`}>{translations[item]}</Link>
      </li>
    ))}
  </ul>
);

const UserStats = ({ balance, level, xp, maxXP, xpPercent, translations, onWatchAd }) => (
  <div className="user-stats">
    <Balance balance={balance} onWatchAd={onWatchAd} />
    <div className="level">{translations.level}: {level}</div>
    <XPBar xp={xp} maxXP={maxXP} xpPercent={xpPercent} translations={translations} />
  </div>
);

const Balance = ({ balance, onWatchAd }) => (
  <div className="balance-container">
    <img src="dollar_icon.png" className="dollar-icon"></img>
    <span className="balance">{balance}</span>
    <Link to="/Shop" className="add-balance-button">
      <img src="square-plus-solid.png" alt="Add Balance" />
    </Link>
    <button onClick={onWatchAd} className="video-button">
      <img src="video_icon.png" alt="Watch Ad" />
    </button>
  </div>
);

const XPBar = ({ xp, maxXP, xpPercent, translations }) => (
  <>
    <div className="xp-bar-container">
      <div id="xp-bar" style={{ width: `${xpPercent}%` }}></div>
    </div>
    <div className="xp-info">
      {xp} / {maxXP.toFixed(0)} {translations.xp}
    </div>
  </>
);

const UserProfile = ({ avatar, username, onProfileEdit }) => (
  <div className="profile">
    <img src={avatar} alt="Profile Avatar" className="profile-avatar" />
    <span className="username">{username}</span>
    <button onClick={(e) => { e.preventDefault(); onProfileEdit(); }} className="edit-icon">
      <img src="edit_icon.png" alt="Edit Icon" />
    </button>
  </div>
);

const AdOverlay = ({ onClose }) => (
  <div
    id="ad-opener"
    style={{
      display: 'flex',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1000,
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      fontSize: '1.5rem',
      textAlign: 'center',
      cursor: 'pointer',
    }}
    onClick={onClose}
  >
    <p>Click anywhere to close this ad overlay</p>
  </div>
);

export default Navigation;
