import React, { useEffect, useState } from 'react';

// Add translations for Navigation and Inventory components
const translations = {
  en: {
    welcome: "Welcome to Case Clicker!",
    subheading: "Complete missions, open cases, and win big!",
    startPlaying: "Start Playing",
    editProfile: "Edit Profile",
    dragDropImage: "Drag and drop an image here or click to upload",
    imageUploaded: "Image uploaded successfully!",
    changeUsername: "Change Username:",
    saveChanges: "Save Changes",
    chooseCase: "Choose a Case to Open",
    contact: "Contact",
    about: "About Us",
    privacyPolicy: "Privacy Policy",
    termsOfService: "Terms of Service",
    language: "Language:",
    clicker: "Clicker",
    cases: "Cases",
    games: "Games",
    inventory: "Inventory",
    missions: "Missions",
    balance: "Balance",
    level: "Lv",
    xp: "XP",
    errorLoginMessage: "You are not logged in. Please log in to save your progress, access your balance, and unlock all game features.",

    // Inventory translations
    inventoryTitle: "Your Inventory",
    priceFilter: "Price (less than):",
    sortByPrice: "Sort by Price",
    ascending: "Ascending",
    descending: "Descending",
    clearInventory: "Sell Inventory",
    loadingItems: "Loading items...",
    errorLoading: "Error loading inventory.",
    quality: "Quality",
    price: "Price"
  },
  ru: {
    welcome: "Добро пожаловать в Case Clicker!",
    subheading: "Выполняйте миссии, открывайте кейсы и выигрывайте большие призы!",
    startPlaying: "Начать Игру",
    editProfile: "Редактировать Профиль",
    dragDropImage: "Перетащите изображение сюда или нажмите, чтобы загрузить",
    imageUploaded: "Изображение успешно загружено!",
    changeUsername: "Изменить Имя Пользователя:",
    saveChanges: "Сохранить Изменения",
    chooseCase: "Выберите кейс для открытия",
    contact: "Контакт",
    about: "О Нас",
    privacyPolicy: "Политика конфиденциальности",
    termsOfService: "Условия предоставления услуг",
    language: "Язык:",
    clicker: "Кликер",
    cases: "Кейсы",
    games: "Игры",
    inventory: "Инвентарь",
    missions: "Миссии",
    balance: "Баланс",
    level: "Ур",
    xp: "Опыт",
    errorLoginMessage: "Вы не вошли в систему. Пожалуйста, войдите, чтобы сохранить свой прогресс, получить доступ к своему балансу и разблокировать все функции игры.",

    // Inventory translations
    inventoryTitle: "Ваш Инвентарь",
    priceFilter: "Цена (меньше чем):",
    sortByPrice: "Сортировать по цене",
    ascending: "По возрастанию",
    descending: "По убыванию",
    clearInventory: "Продать инвентарь",
    loadingItems: "Загрузка предметов...",
    errorLoading: "Ошибка при загрузке инвентаря.",
    quality: "Качество",
    price: "Цена"
  }
};

const LanguageContext = React.createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');

  useEffect(() => {
    localStorage.setItem('language', language);
    updateTranslations(language);
  }, [language]);

  const updateTranslations = (lang) => {
    const elements = {
      welcome: document.querySelector('.container h1'),
      subheading: document.querySelector('.container .subheading'),
      startPlaying: document.querySelector('.cta-button'),
      editProfile: document.querySelector('#profile-modal h2'),
      dragDropImage: document.querySelector('#drop-area p'),
      imageUploaded: document.querySelector('#upload-message'),
      changeUsername: document.querySelector('label[for="username"]'),
      saveChanges: document.querySelector('#profile-form button[type="submit"]'),
      chooseCase: document.querySelector('.cases-section h2'),
      contact: document.querySelector('.footer-links a[href="contact.html"]'),
      about: document.querySelector('.footer-links a[href="about.html"]'),
      privacyPolicy: document.querySelector('.footer-links a[href="privacy.html"]'),
      termsOfService: document.querySelector('.footer-links a[href="terms.html"]'),
      language: document.querySelector('.footer-language label[for="language"]')
    };

    Object.keys(elements).forEach(key => {
      if (elements[key]) {
        elements[key].textContent = translations[lang][key];
      }
    });

    // Handle special case for image upload message
    if (elements.imageUploaded) {
      elements.imageUploaded.style.display = 'none'; // Hide by default
    }
  };

  return (
    <LanguageContext.Provider value={{ language, setLanguage, translations: translations[language] }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => {
  return React.useContext(LanguageContext);
};
