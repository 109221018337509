// Import increaseBalance from balance.js
import { increaseBalance } from './balance'; // Assuming both files are in the same directory


// Helper function to generate a unique ID
const generateUniqueId = () => {
  return Date.now() + Math.random().toString(36).substr(2, 9);
};


const getSkinsFromLocalStorage = () => JSON.parse(localStorage.getItem('skins')) || [];

// Function to remove a case from local inventory based on name
export const remCaseFromLocalInventory = async (name) => {
  try {
    const localSkins = getSkinsFromLocalStorage();
    const inventoryArray = [...localSkins]; // Prepare the inventory array for the backend

    console.log('Sending payload:', { inventoryArray, name: name });

    const response = await fetch(`/api/incCase`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ inventoryArray, name: name }), // Send the ID to remove
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error('Backend error:', errorData);
      throw new Error(errorData.error || 'Failed to remove item');
    }

    const data = await response.json();
    console.log('Updated tokens:', data.tokens);

    // Update local storage with new tokens from the backend
    localStorage.setItem('skins', JSON.stringify(data.tokens));
    console.log(`Case with name '${name}' removed from local inventory.`);
  } catch (error) {
    console.error('Error removing case:', error);
  }
};

// Function to remove an item from local inventory by its ID
export const remItemFromLocalInventory = (itemId) => {
  let isRemoved = false;

  // Check skins
  let localInvSkins = getSkinsFromLocalStorage();
  const skinIndex = localInvSkins.findIndex(item => item.id === itemId);
  if (skinIndex !== -1) {
    localInvSkins.splice(skinIndex, 1);
    localStorage.setItem('skins', JSON.stringify(localInvSkins));
    console.log(`Skin with ID '${itemId}' removed from local inventory.`);
    isRemoved = true;
  }

  if (!isRemoved) {
    console.log(`Item with ID '${itemId}' not found in local inventory.`);
  }
};

// Function to add skin to local inventory
export const addSkinToLocalInventory = (item) => {
  const localInvSkins = getSkinsFromLocalStorage();

  const newSkin = { ...item, id: generateUniqueId() }; // Add unique ID only
  localInvSkins.push(newSkin);

  localStorage.setItem('skins', JSON.stringify(localInvSkins));
  console.log('Skin added to local inventory:', item);
  console.log(JSON.stringify(localInvSkins))
};

export const sellInventory = () => {
  try {
    let totalPrice = 0;

    // Calculate total value from skins
    const localInvSkins = getSkinsFromLocalStorage();
    localInvSkins.forEach(s => totalPrice += parseFloat(s.price) || 0);

    // Logging for debugging to ensure this function doesn't loop
    console.log(`Total price to add to balance: $${totalPrice}`);

    // Increase balance with total price
    increaseBalance(totalPrice);

    // Clear local inventory (shouldn't cause any issues but double check any triggers)
    localStorage.removeItem('skins');
    
    console.log(`Inventory sold for a total of $${totalPrice}. Balance updated.`);

  } catch (error) {
    console.error('Error while selling inventory:', error);
  }
};

