import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function CasesSection() {
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [cases, setCases] = useState([]); 
  const [displayedCases, setDisplayedCases] = useState([]); 
  const navigate = useNavigate(); 

  // Fetch case data from backend
  useEffect(() => {
    const fetchCases = async () => {
      try {
        const response = await fetch(`/api/cases`);
        if (!response.ok) {
          throw new Error('Failed to fetch cases');
        }
        const data = await response.json();
        const shuffledCases = data.sort(() => Math.random() - 0.5);
        const selectedCases = shuffledCases.slice(0, 7).sort((a, b) => a.price - b.price);

        setCases(data); 
        setDisplayedCases(selectedCases);
      } catch (error) {
        console.error('Error fetching cases:', error);
      }
    };
    fetchCases();
  }, []);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseLeave = () => setIsDragging(false);
  const handleMouseUp = () => setIsDragging(false);
  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleCaseButtonClick = (caseItem) => {
    navigate('/caseopener', { state: { selectedCase: caseItem } });
  };

  return (
    <div className="cases-section">
      <h2>Choose a Case to Open</h2>
      <div
        className="cases-container"
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        {displayedCases.map((caseItem) => (
          <div key={caseItem.id} className="case-item">
            <img src={caseItem.image} alt={caseItem.name} />
            <p>{caseItem.name}</p>
            <button
              className="case-button"
              onClick={() => handleCaseButtonClick(caseItem)}
            >
              ${caseItem.price}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CasesSection;
