import React from 'react';
import Navigation from './Navigation';
import Footer from './Footer';
import Modal from './Modal';
import Games from './Games';
import { LanguageProvider } from './language'; // Import LanguageProvider
import './index.css'; // Import global CSS styles (if you have any)

function GamesPage() {
  return (
    <LanguageProvider>
      <>
      <Navigation />
      <Modal />
      <Games />
      <Footer />
      </>
    </LanguageProvider>
  );
}

export default GamesPage;
