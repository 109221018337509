// src/Games.js

import React, { useState, useEffect } from 'react';

// Updated games array to mark availability
const games = [
  { name: 'Roulette', image: '/roulette.jpg', status: 'available' },
  { name: 'Coin Flip', image: '/coin_flip.jpg', status: 'available' },
  { name: 'Upgrader', image: '/upgrader_try.webp', status: 'available' },
  { name: 'Jackpot', image: '/jackpot.png', status: 'comingSoon' },
  { name: 'Case Battle', image: '/case_battle.png', status: 'comingSoon' },
  { name: 'Create Case', image: '/create_case.png', status: 'comingSoon' },
  { name: 'Minesweeper', image: '/minesweeper.png', status: 'inDevelopment' },
  { name: 'Tower', image: '/tower.png', status: 'inDevelopment' },
  { name: 'Cards', image: '/cards.png', status: 'inDevelopment' },
  { name: 'Chicken Race', image: '/chicken_race.png', status: 'comingSoon' },
];

const Games = () => {
  return (
    <div className="games-page">
      <div className="games-title">
        <h1>Games</h1>
        <p className="games-subtitle">
          Explore our current games! More modes are <strong>coming soon</strong> or <strong>in development</strong>.
        </p>
      </div>

      <div className="games-container">
        {games.map((game, index) => (
          <div 
          className={`game-tile ${game.status}`} 
          key={index}
          style={{ backgroundImage: `url(${game.image})` }} // Set background image
        >
          <div className="game-tile-content">
            <h2 className="game-name">{game.name}</h2>
            <p className="game-status">
              {game.status === 'available' && 'Available'}
              {game.status === 'comingSoon' && 'Coming Soon'}
              {game.status === 'inDevelopment' && 'In Development'}
            </p>
          </div>
        </div>
        
        ))}
      </div>
    </div>
  );
};

export default Games;
