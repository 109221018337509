import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-page">
      <div className="privacy-container">
        <h1>Privacy Policy</h1>
        <p className="subheading">Effective Date: October 13, 2024</p>

        <section>
          <h2>1. Introduction</h2>
          <p>
            Welcome to Case Clicker. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website,{' '}
            <a href="https://caseclicker.online">caseclicker.online</a>. Please read this policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.
          </p>
        </section>

        <section>
          <h2>2. Information We Collect</h2>
          <p>We may collect information about you in a variety of ways, including:</p>
          <ul>
            <li>
              <strong>Personal Data:</strong> Name, email address, and other information that you provide when you create an account or interact with our services.
            </li>
            <li>
              <strong>Data Automatically Collected:</strong> IP address, browser type, device information, and usage data that help us improve your experience.
            </li>
            <li>
              <strong>Cookies:</strong> We use cookies to enhance site navigation, analyze usage, and assist in marketing efforts. 
            </li>
          </ul>
        </section>

        <section>
          <h2>3. How We Use Your Information</h2>
          <p>Your information allows us to:</p>
          <ul>
            <li>Personalize your gaming experience and display tailored content.</li>
            <li>Improve our website and services based on feedback and performance data.</li>
            <li>Respond to inquiries, support requests, and process your transactions.</li>
            <li>Comply with legal obligations and prevent fraudulent activities.</li>
          </ul>
        </section>

        <section>
          <h2>4. Sharing of Information</h2>
          <p>We may share your information with:</p>
          <ul>
            <li>
              <strong>Third-Party Service Providers:</strong> Vendors and partners who assist in operating our website and services.
            </li>
            <li>
              <strong>Legal Authorities:</strong> When required to comply with the law or in response to legal requests.
            </li>
            <li>
              <strong>Business Transfers:</strong> In the event of a merger, sale, or acquisition, your data may be transferred to the new entity.
            </li>
          </ul>
        </section>

        <section>
          <h2>5. Data Security</h2>
          <p>
            We use industry-standard security measures to protect your data. However, please note that no method of data transmission or storage is completely secure, and we cannot guarantee absolute protection.
          </p>
        </section>

        <section>
          <h2>6. Your Privacy Rights</h2>
          <p>
            Depending on your jurisdiction, you may have the following rights regarding your personal information:
          </p>
          <ul>
            <li>Access and request a copy of your data.</li>
            <li>Request correction or deletion of your information.</li>
            <li>Object to the processing of your data.</li>
            <li>Withdraw consent at any time.</li>
          </ul>
          <p>
            To exercise these rights, please contact us at <a href="mailto:caseclickerhelp@yahoo.com">caseclickerhelp@yahoo.com</a>.
          </p>
        </section>

        <section>
          <h2>7. Cookies and Tracking Technologies</h2>
          <p>
            Our website uses cookies to enhance your experience. You can disable cookies through your browser settings; however, this may impact certain functionalities of the website.
          </p>
        </section>

        <section>
          <h2>8. Policy Updates</h2>
          <p>
            We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting a notice on our website or emailing you. Please review this policy periodically.
          </p>
        </section>

        <section>
          <h2>9. Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy, please contact us at:
          </p>
          <p>
            <strong>Email:</strong> <a href="mailto:caseclickerhelp@yahoo.com">caseclickerhelp@yahoo.com</a>
          </p>
          <p>
            <strong>Mailing Address:</strong> Case Clicker, 1234 Game Street, Virtual City, VG 56789
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
