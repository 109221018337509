import React from 'react';

const TermsOfService = () => {
  return (
    <div className="terms-of-service-page">
      <div className="privacy-container">
        <h1>Terms of Service</h1>
        <p className="subheading">Effective Date: October 13, 2024</p>

        <section>
          <h2>1. Acceptance of Terms</h2>
          <p>
            By accessing or using <a href="https://caseclicker.online">caseclicker.online</a> ("Website"), you agree to these Terms of Service ("Terms"). If you do not accept these Terms or any part of them, please discontinue your use of the Website immediately.
          </p>
          <p>
            Your participation in gambling activities on the Website is subject to compliance with these Terms, applicable laws, and regulations.
          </p>
        </section>

        <section>
          <h2>2. Eligibility and Age Restrictions</h2>
          <p>
            You must be at least 18 years old or the legal age for gambling in your jurisdiction to use this Website. By creating an account, you confirm that you meet the minimum age requirement.
          </p>
          <p>
            We reserve the right to request proof of age at any time and may suspend or terminate your account if we believe you are underage.
          </p>
        </section>

        <section>
          <h2>3. Account Registration and Security</h2>
          <p>
            To participate in gambling activities, you must create an account with accurate and complete information. You are responsible for maintaining the confidentiality of your account credentials and for all activities under your account.
          </p>
          <p>
            If you suspect unauthorized access to your account, contact us immediately at <a href="mailto:caseclickerhelp@yahoo.com">caseclickerhelp@yahoo.com</a>. We are not liable for losses resulting from unauthorized access.
          </p>
        </section>

        <section>
          <h2>4. Responsible Gambling</h2>
          <p>
            We are committed to promoting responsible gambling. If you feel you are developing a gambling problem, please seek help through resources such as <a href="https://www.begambleaware.org">BeGambleAware</a>.
          </p>
          <p>
            Users may request account limits, temporary suspension, or permanent self-exclusion by contacting us.
          </p>
        </section>

        <section>
          <h2>5. Prohibited Activities</h2>
          <p>You agree not to:</p>
          <ul>
            <li>Provide false information during account registration.</li>
            <li>Allow others to use your account or gamble on your behalf.</li>
            <li>Manipulate or cheat the Website through unauthorized software or bots.</li>
            <li>Engage in fraudulent activities, money laundering, or unauthorized transfers.</li>
          </ul>
          <p>Violation of these rules may result in account suspension, confiscation of winnings, and legal action.</p>
        </section>

        <section>
          <h2>6. Risk Disclosure</h2>
          <p>
            Gambling involves financial risk, and you may lose some or all of your money. By using the Website, you acknowledge that all wagers are placed at your own risk, and we are not liable for any losses.
          </p>
        </section>

        <section>
          <h2>7. Deposits, Withdrawals, and Payments</h2>
          <p>
            All deposits and withdrawals must be made through the methods available on the Website. We reserve the right to request verification documents (e.g., ID, address proof) to process withdrawals.
          </p>
          <p>
            We are not responsible for delays caused by third-party payment providers.
          </p>
        </section>

        <section>
          <h2>8. Intellectual Property</h2>
          <p>
            All content on the Website, including games, software, and designs, is the property of Case Clicker or its licensors. You are granted a limited license to access the Website for personal use only. Unauthorized use of our intellectual property is strictly prohibited.
          </p>
        </section>

        <section>
          <h2>9. Limitation of Liability</h2>
          <p>
            We provide the Website on an "as-is" and "as-available" basis. To the fullest extent permitted by law, we disclaim all warranties, express or implied, regarding the operation, accuracy, or availability of the Website.
          </p>
          <p>
            Case Clicker is not liable for any losses, including financial losses, arising from the use of the Website or inability to access it.
          </p>
        </section>

        <section>
          <h2>10. Governing Law and Dispute Resolution</h2>
          <p>
            These Terms are governed by the laws of [Your Jurisdiction]. Any disputes related to these Terms or the Website will be resolved through binding arbitration or the courts located in [Your Jurisdiction], as applicable.
          </p>
        </section>

        <section>
          <h2>11. Termination of Account</h2>
          <p>
            We reserve the right to suspend or terminate your account at our sole discretion if you breach these Terms or engage in prohibited activities. Upon termination, any remaining balances in your account may be forfeited.
          </p>
        </section>

        <section>
          <h2>12. Severability</h2>
          <p>
            If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.
          </p>
        </section>

        <section>
          <h2>13. Contact Us</h2>
          <p>
            If you have any questions or concerns about these Terms, please contact us at:
          </p>
          <p>
            <strong>Email:</strong> <a href="mailto:caseclickerhelp@yahoo.com">caseclickerhelp@yahoo.com</a>
          </p>
          <p>
            <strong>Mailing Address:</strong> Case Clicker, 1234 Game Street, Virtual City, VG 56789
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfService;
