import React from 'react';
import Navigation from './Navigation.js';
import Inventory from './Inventory.js';
import Footer from './Footer.js';
import Modal from './Modal.js';
import { LanguageProvider } from './language.js'; // Import LanguageProvider
import './index.css'; // Import global CSS styles (if you have any)

function InventoryPage() {
  return (
    <LanguageProvider>
      <>
      <Navigation />
      <Inventory />
      <Modal />
      <Footer />
      </>
    </LanguageProvider>
  );
}

export default InventoryPage;
