import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css'; // Import CSS for styling
import updateCaseCounts from './functions/countCases'; // Import the countCases function

function CaseChoice() {
  const [cases, setCases] = useState([]); // State to hold case data
  const [ownedCases, setOwnedCases] = useState({}); // State to track how many cases are owned
  const [priceFilter, setPriceFilter] = useState(''); // State for price filter
  const [sortOrder, setSortOrder] = useState('asc'); // State for sort order
  const navigate = useNavigate(); // Initialize useNavigate

  // Fetch case data from the backend on component mount
  useEffect(() => {
    const fetchCases = async () => {
      try {
        const response = await fetch(`/api/cases`); // Fetch from backend
        if (!response.ok) {
          throw new Error('Failed to fetch cases');
        }
        const data = await response.json();
        setCases(data); // Set the cases data in state

        // Load and update owned cases once the cases are fetched
        const caseCount = await updateCaseCounts(data);
        setOwnedCases(caseCount); // Store the count of owned cases
      } catch (error) {
        console.error('Error fetching cases:', error);
      }
    };

    fetchCases();
  }, []); // Run on component mount

  // Function to handle case button click
  const handleCaseButtonClick = (caseItem) => {
    navigate('/caseopener', { state: { selectedCase: caseItem } }); // Redirect to CaseOpenerPage with selected case
  };

  // Function to toggle sort order
  const toggleSortOrder = () => {
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  // Filter cases based on price and sort
  const filteredCases = cases
    .filter((caseItem) => {
      return priceFilter === '' || caseItem.price < priceFilter; // Apply price filter
    })
    .sort((a, b) => {
      return sortOrder === 'desc' ? a.price - b.price : b.price - a.price; // Sort by price
    });

  return (
    <div className="case-choice">
      <h2>Choose a Case to Open</h2>
      <div className="filter-section">
        <label>
          Price (less than):
          <input
            type="number"
            value={priceFilter}
            onChange={(e) => setPriceFilter(e.target.value)}
            placeholder="Enter max price"
          />
        </label>
        <button onClick={toggleSortOrder} className="sort-button">
          Sort by Price ({sortOrder === 'asc' ? 'Ascending' : 'Descending'})
        </button>
      </div>
      <div className="cases-grid">
        {filteredCases.map((caseItem) => {
          const ownedCount = ownedCases[caseItem.name] || 0; // Get the number of cases owned, default to 0 if none
          const isOwned = ownedCount > 0; // Check if the user owns any of the case

          return (
            <div key={caseItem.id} className={`case-item ${isOwned ? 'owned' : 'not-owned'}`}>
              <img src={caseItem.image} alt={caseItem.name} />
              <p className="case-name">{caseItem.name}</p>
              <button
                className="case-button"
                onClick={() => handleCaseButtonClick(caseItem)}
              >
                {isOwned ? `Open Case (${ownedCount})` : `Buy Case - $${caseItem.price}`}
              </button>
              <p className="owned-count">Owned: {ownedCount}</p> {/* Display owned count */}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default CaseChoice;
