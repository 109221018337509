import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from './language';

function Container() {
  const { translations } = useLanguage();

  useEffect(() => {
    // Set options for the banner
    const atOptions = {
      key: '3494d5876360a9ea6a5529d65bea340b',
      format: 'iframe',
      height: 90,
      width: 728,
      params: {},
    };

    // Set up Adsterra banner script
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://agencyrearrangepasture.com/3494d5876360a9ea6a5529d65bea340b/invoke.js';
    script.async = true; // Make sure the script loads asynchronously

    // Handle script loading errors
    script.onerror = () => {
      console.error('Failed to load the Adsterra banner script');
    };

    // Find the ad banner container and append the script there
    const adBanner = document.querySelector('.ad-banner');
    adBanner.appendChild(script);

    // Ensure to set the options before invoking the script
    window.atOptions = atOptions;

    // Cleanup function to remove the script when the component unmounts
    return () => {
      adBanner.removeChild(script);
      delete window.atOptions;
    };
  }, []);

  return (
    <div className="container">
      <h1>{translations.welcome}</h1>
      <p className="subheading">{translations.subheading}</p>
      <Link to="/clicker">
        <button className="cta-button">{translations.startPlaying}</button>
      </Link>

      {/* Adsterra Banner */}
      <div className="ad-banner">
        {/* The banner will be injected by the script */}
      </div>
    </div>
  );
}

export default Container;
