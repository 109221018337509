import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { registerErrorFieldSetter } from './functions/errors';
import { useLanguage } from './language';

const ErrorField = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { translations } = useLanguage();
  const navigate = useNavigate();

  const handleProfileEdit = () => {
    console.log('Opening profile edit modal');
    document.getElementById('profile-modal').style.display = 'flex';
  };

  const handleRedirectToShop = () => {
    console.log('Redirecting to shop');
    navigate('/shop'); // Use navigate function for client-side redirection
  };

  const handleErrorClick = () => {
    if (errorMessage.toLowerCase().includes('log in') || errorMessage.toLowerCase().includes('authentication')) {
      handleProfileEdit();
    } else if (errorMessage.toLowerCase().includes('balance')) {
      handleRedirectToShop();
    }
  };

  useEffect(() => {
    // Register the setErrorMessage function to handle outside error setting
    registerErrorFieldSetter((message) => {
      setErrorMessage(message);
      setIsVisible(true);

      // Hide the error message after 5 seconds
      setTimeout(() => {
        setIsVisible(false);
      }, 5000);
    });

    // Fetch login status from the backend
    const checkLoginStatus = async () => {
      try {
        const response = await fetch('/api/auth/check-auth', { credentials: 'include' });
        const data = await response.json();
        setIsLoggedIn(data.isLoggedIn);
        if (!data.isLoggedIn) {
          const loginMessage = translations?.errorLoginMessage || "You are not logged in. Please log in to save your progress, access your balance, and unlock all game features.";
          setErrorMessage(loginMessage);
          setIsVisible(true);
        }
      } catch (error) {
        console.error('Error checking login status:', error);
      }
    };

    checkLoginStatus();

    // Add animation styles if not already added
    const styleSheet = document.styleSheets[0];
    if (styleSheet) {
      styleSheet.insertRule(`
        @keyframes slideIn {
          from { transform: translateY(100%); opacity: 0; }
          to { transform: translateY(0); opacity: 1; }
        }
      `, styleSheet.cssRules.length);

      styleSheet.insertRule(`
        @keyframes fadeOut {
          from { opacity: 1; }
          to { opacity: 0; }
        }
      `, styleSheet.cssRules.length);
    } else {
      console.error("Style sheet is not available.");
    }

  }, [translations]);

  if (isLoggedIn && !isVisible) return null;

  return (
    <div style={{
      ...styles.errorContainer,
      opacity: isVisible ? 1 : 0,
      animation: isVisible ? 'slideIn 0.5s ease, fadeOut 5s ease 4.5s forwards' : 'none',
      pointerEvents: isVisible ? 'auto' : 'none' // Prevents clicks when hidden
    }}>
      <p style={styles.errorMessage} onClick={handleErrorClick}>
        {errorMessage}
      </p>
    </div>
  );
};

// CSS styles with animation keyframes
const styles = {
  errorContainer: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    backgroundColor: '#ff4d4d',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '5px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    fontSize: '14px',
    zIndex: 1000,
    cursor: 'pointer',
  },
  errorMessage: {
    margin: 0,
    fontWeight: 'bold',
  },
};

export default ErrorField;
