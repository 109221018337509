import React from 'react';
import { Link } from 'react-router-dom';
import './PopupList.css';

const PopupList = () => {

  // Function to trigger the Lucky Wheel modal
  const triggerLuckyWheel = () => {
    const luckyWheelCont = document.getElementById('lucky-wheel-cont');
    if (luckyWheelCont) {
      luckyWheelCont.style.display = 'flex'; // Show the modal
    } else {
      console.error('Lucky wheel modal element not found');
    }
  };

  // List of popup items with unique handlers
  const popups = [
    {
      id: 1,
      className: 'lucky-wheel-popup',
      imgSrc: '/lucky_wheel_icon.png',
      altText: 'Lucky Wheel',
      onClick: triggerLuckyWheel, // Trigger Lucky Wheel modal
    },
    {
      id: 2,
      className: 'casino-popup',
      imgSrc: '/casino_popup.png',
      altText: 'Casino Popup',
      onClick: () => {}, // Empty onClick, using Link below
      link: '/casino', // Path for the Casino route
    },
    // Future popups can be added here
  ];

  return (
    <div className="popup-list">
      {popups.map((popup) => (
        <div key={popup.id} className={popup.className} onClick={popup.onClick}>
          {popup.link ? (
            <Link to={popup.link}>
              <img src={popup.imgSrc} alt={popup.altText} />
            </Link>
          ) : (
            <img src={popup.imgSrc} alt={popup.altText} />
          )}
        </div>
      ))}
    </div>
  );
};

export default PopupList;
