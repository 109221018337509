import React from 'react';
import { useSpring, useTrail, animated, config } from 'react-spring'; // Animation library

const About = () => {
  // Simplified animations
  const fadeIn = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 200,
    config: config.gentle,
  });

  const trail = useTrail(4, {
    opacity: 1,
    x: 0,
    from: { opacity: 0, x: -20 },
    config: config.wobbly,
  });

  const slideUpHeader = useSpring({
    transform: 'translateY(0)',
    from: { transform: 'translateY(50px)' },
    delay: 300,
    config: config.slow,
  });

  const teamImageAnimation = useSpring({
    transform: 'scale(1)',
    from: { transform: 'scale(0.8)' },
    delay: 500,
    config: config.stiff,
  });

  return (
    <div className="about-page">
      <header className="about-header">
        <animated.h1 style={fadeIn}>About Case Clicker</animated.h1>
        <animated.p style={fadeIn}>
          Case Clicker is more than just a game. It’s an exciting journey of missions, rewards, and surprises. 
          Dive into a world where every click brings you closer to your next achievement!
        </animated.p>
      </header>

      <section className="features-section">
        <h2>Features</h2>
        <ul className="feature-list">
          {trail.map((style, index) => (
            <animated.li key={index} style={style}>
              {[
                '🎯 Complete daily missions and unlock exclusive rewards.',
                '🎲 Open virtual cases to win prizes and collectibles.',
                '📊 Track your progress and achievements in real-time.',
                '🌍 Compete globally and secure a spot on the leaderboard.',
              ][index]}
            </animated.li>
          ))}
        </ul>
      </section>

      <section className="team-section">
        <h2>Meet the Team</h2>
        <div className="team-grid">
          <animated.div className="team-member" style={teamImageAnimation}>
            <img
              src="linkedin_prof.jpg"
              alt="Artemii Bakharev"
              className="team-image"
            />
            <h3>Artemii Bakharev</h3>
            <p>Founder & Lead Developer</p>
            <a
              href="https://www.linkedin.com/in/artemii-bakharev-1ba282227/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          </animated.div>
        </div>
      </section>

      <section className="technology-section">
        <h2>Technologies We Use</h2>
        <p>
          At Case Clicker, we utilize modern technologies to deliver the best possible experience:
        </p>
        <ul className="tech-stack">
          <li>⚛️ <strong>React:</strong> Frontend development</li>
          <li>📦 <strong>Supabase:</strong> Backend and authentication</li>
          <li>💅 <strong>Figma:</strong> UI/UX design</li>
          <li>☁️ <strong>Cloud Services:</strong> Reliable performance</li>
        </ul>
      </section>

      <section className="contact-section">
        <h2>Get in Touch</h2>
        <p>
          Have questions or feedback? Feel free to reach out via our <a href="/contact">contact page</a>.
        </p>
      </section>
    </div>
  );
};

export default About;
