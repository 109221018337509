import React from 'react';
import Navigation from './Navigation.js';
import About from './About.js';
import Footer from './Footer.js';
import Modal from './Modal.js';
import { LanguageProvider } from './language.js'; // Import LanguageProvider
import './index.css'; // Import global CSS styles (if you have any)

function AboutPage() {
  return (
    <LanguageProvider>
      <>
      <Navigation />
      <About />
      <Modal />
      <Footer />
      </>
    </LanguageProvider>
  );
}

export default AboutPage;
